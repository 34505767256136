.dog-puller-is {
  margin-bottom: 20px;
}

.dog-puller-is__text {
  color: #535353;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 18px;
  p {
    line-height: 1.75;
    padding-right: 55px;
    margin-bottom: 15px;
    &:first-child {
      margin-top: 35px;
    }
  }
}

.results-table {
  width: 100%;
  border: 1px solid #cacaca;
  font-size: 0.875em;
  text-align: left;
  border-collapse: collapse;
  margin-bottom: 65px;
}

.results-table__head {
  font-family: 'ProximaNova-Semibold';
}

.results-table__body {
  color: #929292;
}

.post__cover {
  display: block;
}

.results-table__td {
  padding: 17px 15px;
  width: 25%;
  border: 1px solid #cacaca;
}

.results-table tr:nth-child(odd) td {
  background-color: #f4f4f7;
}

.quiz__questionnaire-btn-bg {
  padding: 130px 10px 120px;
  background: url(../img/quiz__btn-bg.jpg);
  background-size: cover;
  text-align: center;
}

.club-rules__h2 {
  margin-bottom: 15px;
  color: #535353;
  font-family: 'ProximaNova-Regular';
  font-size: 1.25em;
  text-transform: uppercase;
}

.club-rules__info {
  text-align: center;
  padding: 90px 10px;
  background-color: #f2f3f5;
}

.recommendation {
  padding-bottom: 75px;
}

.accordion-container {
  margin-bottom: 50px;
}

.photo-team {
  padding: 4px 4px 25px;
  margin-bottom: 30px;
}

.photo__img {
  height: 320px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    transform: translateX(-50%) translateY(-50%);
  }
}

.photo-team__title {
  color: #282828;
  font-family: 'ProximaNova-Regular';
  font-size: 1.25em;
  position: relative;
  padding: 0 45px 10px 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
  .country-img {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(-50%) translateY(-50%);
    padding-bottom: 10px;
  }
}

.photo-team__text {
  color: #282828;
  font-family: 'ProximaNova-Regular';
  font-size: 0.875em;
  p:first-child {
    margin-bottom: 15px;
  }
}

.video__read-more {
  a {
    display: inline-block;
    color: #282828;
    border-bottom: 1px solid #e5e5e5;
  }
  &:hover {
    color: #282828;
  }
}

.species-subtitle {
  max-width: 320%;
  text-align: center;
  margin: 25px auto;
}

.records-archive {
  margin-bottom: 30px;
}

.records-month-nav {
  height: 100%;
  max-width: 310px;
  max-height: 300px;
}

.records-month-nav__month-wrap {
  padding: 25px 0 15px 30px;
  float: left;
  column-count: 2;
}

.news-subscribe {
  background-color: #f4f4f4;
  padding: 50px;
  input {
    &::-webkit-input-placeholder {
      color: rgba(44, 44, 44, 0.5);
    }
    &::-moz-placeholder {
      color: rgba(44, 44, 44, 0.5);
    }
    &:-ms-input-placeholder {
      color: rgba(44, 44, 44, 0.5);
    }
    &:-moz-placeholder {
      color: rgba(44, 44, 44, 0.5);
    }
  }
}

.news-subscribe-text {
  color: #2c2c2c;
  font-family: 'ProximaNova-Regular';
  font-size: 1.25em;
  margin-bottom: 20px;
}

.news-subscribe__btn-wrap {
  text-align: center;
  margin-top: 15px;
}

.news-subscribe__btn {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 35px;
  color: #ffffff;
  font-family: 'ProximaNova-Semibold';
  font-size: 0.875em;
  text-transform: uppercase;
}

.photo-team__data {
  margin: 15px 0;
  p, a {
    color: #282828;
    font-family: 'ProximaNova-Regular';
    font-size: 0.875em;
  }
  a, a:hover {
    display: block;
    color: #282828;
    text-decoration: underline;
  }
}

.command, .photo-gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.photo-gallery__img {
  height: 320px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    transform: translateX(-50%) translateY(-50%);
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: 0.3s;
  }
}

.gallery-section {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 15px;
  margin: 15px;
  background: #ffffff;
  color: #7b7b7b;
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
  text-transform: uppercase;
  z-index: 1;
}

.photo-gallery-news {
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.photo-gallery-news_img {
  overflow: hidden;
  height: 100%;
  position: relative;
  min-height: 250px;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    transform: translateX(-50%) translateY(-50%);
  }
}

.photo-gallery-news-text__text {
  color: #2c2c2c;
  font-family: 'ProximaNova-Regular';
  .photo-gallery-news-text__title {
    font-size: 1.375em;
  }
  &:last-child {
    opacity: 0.6;
    font-size: 0.875em;
  }
}

.photo-gallery-news-text__date {
  color: #000000;
  font-size: 0.75em;
  margin: 15px 0;
  img {
    margin-left: 10px;
  }
}

.photo-gallery-news-text__btn {
  margin-top: 15px;
}

.photo-gallery-news-text {
  height: 100%;
  margin-left: -20px;
  padding: 20px;
  background: #f4f4f4;
}

.gallery-arrow {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 15px;
  margin: 15px;
  z-index: 1;
  display: none;
}

.date-img {
  color: #ffffff;
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px 15px;
  margin: 15px;
  z-index: 1;
}

.photo-gallery__text {
  margin: -1px 0 0 0;
  padding: 20px 5px 20px 0;
  border: 1px solid transparent;
  transition: .3s;
}

.photo-gallery__date {
  margin: 5px 0;
  font-family: 'ProximaNova-Regular';
  font-size: 0.75em;
  color: #000000;
  img {
    margin-left: 10px;
  }
}

.photo-gallery-wrap {
  margin-bottom: 20px;
  position: relative;
}

.photo-gallery-wrap:hover {
  .photo-gallery__text {
    padding: 20px 5px 20px 10px;
    border: 1px solid #e3e3e3;
  }
  .photo-gallery__img {
    &:after {
      background: rgba(0, 0, 0, 0.0);
    }
  }
  .gallery-arrow {
    display: block;
  }
}

.gallery-img-mini {
  max-height: 200px;
  overflow: hidden;
}

.results-hr {
  padding-top: 70px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.photo-gallery-row {
  margin-right: -10px;
  margin-left: -10px;
}

.photo-gallery-hr-right {
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  margin-right: -10px;
}

.media-blog__month-h2 {
  text-align: left;
  margin: 0 0 30px 0;
  text-transform: unset;
}

.media-blog__month-nav {
  height: 100%;
  max-width: 310px;
  max-height: 300px;
  display: flex;
}

.media-blog-month-nav__month-wrap {
  padding: 25px 0 15px 30px;
  float: left;
  column-count: 2;
}

.calendar-wrapper {
  padding-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.location {
  margin: 30px 0 50px 0;
}

.open-news {
  padding: 17px 15px;
  width: 50%;
  border: 1px solid #cacaca;
}

.open-record__video {
  width: 80%;
  margin: 40px auto 0 auto;
}

#openNews {
  margin-bottom: 40px;
}

@media (max-width: 989px) {
  .photo-gallery-hr-right {
    border-left: none;
    margin-left: -10px;
  }
}

@media (max-width: 770px) {
  .photo-gallery-news-text {
    margin-left: 0;
  }
}

